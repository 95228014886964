import commonHelper from '@/app/utility/common.helper.utility';
import TaxCatDetails from './taxCatDetails';
export default {
  name: 'TaxCategory',
  components: {
    TaxCatDetails
  },
  props: ['taxCatModalFlag'],
  watch: {
    currentPage: function() {
      this.getTaxCategoryList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTaxCategoryList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      showTaxCatDetails: false,
      loader: false,
      payload: {},
      editMode: false,
      updateMode: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      taxCatName: null,
      data: [],
      fields: [
        {
          key: 'tax_cat_hdr_id',
          class: 'd-none'
        },
        {
          key: 'category_name',
          label: 'Tax Category Name'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'active'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showTaxCatDetails = true;
        }
        if (this.showTaxCatDetails) {
          setTimeout(() => {
            this.eventBus.$emit('createFormActions', actionName);
          }, 0);
        }
        if (actionName === 'download' && !this.showTaxCatDetails) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'taxCat/getTaxCategoryList',
            'Tax-Cat',
            () => (this.loader = false)
          );
        }
      }
    });
    this.getTaxCategoryList();
  },
  methods: {
    getTaxCategoryList() {
      this.payload = {
        tax_category: this.taxCatName,
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.loader = true;
      this.$store
        .dispatch('taxCat/getTaxCategoryList', this.payload)
        .then(response => {
          this.loader = false;
          this.data = response.data.data.page;
          this.totalRows = response.data.data.total_elements;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateTaxCategoryList() {
      this.getTaxCategoryList();
    },
    rowSelected(rowData) {
      if(this.taxCatModalFlag){
        this.$emit('selectedTaxCategory', rowData);
      }
      else {
        this.showTaxCatDetails = true;
        setTimeout(() => {
          this.eventBus.$emit('taxCategoryDetail', rowData);
        }, 0);
      }
    },
    clearSearch() {
      this.taxCatName = null;
    },
    hideTaxCatDetails() {
      this.showTaxCatDetails = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
