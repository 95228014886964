import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import TaxMaster from '../../taxMaster';
import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'TaxCatDetails',
  components: {
    ModelSelect,
    DatePicker,
    TaxMaster
  },
  data() {
    return {
      showTaxCatDetails: false,
      showTaxMasterModule: false,
      loader: false,
      editMode: false,
      updateMode: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      tableIndex: 0,
      form: {
        categoryName: null,
        tax_cat_hdr_id: 0,
        startDate: null,
        endDate: null,
        start_date_end_date: null,
        status: true
      },
      data: [
        {
          line_num: 1,
          tax_cat_dtl_id: 0,
          tax_name: null,
          tax_master_id: null,
          tax_rate: null,
          adhoc: null,
          precedence0: null,
          precedence1: null,
          precedence2: null,
          precedence3: null,
          precedence4: null,
          precedence5: null,
          precedence6: null,
          precedence7: null,
          precedence8: null,
          precedence9: null
        }
      ],
      fields: [
        {
          key: 'line_num',
          label: 'Line No.'
        },
        {
          key: 'tax_cat_dtl_id',
          class: 'd-none'
        },
        {
          key: 'tax_master_id',
          class: 'd-none'
        },
        {
          key: 'tax_name'
        },
        {
          key: 'tax_rate'
        },
        {
          key: 'adhoc',
          class: 'text-center'
        },
        {
          key: 'precedence0',
          label: '0'
        },
        {
          key: 'precedence1',
          label: '1'
        },
        {
          key: 'precedence2',
          label: '2'
        },
        {
          key: 'precedence3',
          label: '3'
        },
        {
          key: 'precedence4',
          label: '4'
        },
        {
          key: 'precedence5',
          label: '5'
        },
        {
          key: 'precedence6',
          label: '6'
        },
        {
          key: 'precedence7',
          label: '7'
        },
        {
          key: 'precedence8',
          label: '8'
        },
        {
          key: 'precedence9',
          label: '9'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ]
    };
  },
  mounted() {
    this.eventBus.$off('createFormActions');
    this.eventBus.$off('taxCategoryDetail');
    this.eventBus.$on('createFormActions', actionName => {
      if (actionName === 'edit' || actionName === 'update') {
        this.editMode = false;
        this.updateMode = true;
      }
      if (actionName === 'add') {
        this.resetform();
        this.showAlert = false;
        this.editMode = true;
        this.updateMode = true;
        this.showTaxCatDetails = true;
      }
      if (actionName === 'save' && this.updateMode) {
        this.addEditTaxCategory();
      }
      if (actionName === 'upload') {
        this.eventBus.$emit('commonUpload', { id: this.form.tax_cat_hdr_id });
      }
    });
    this.eventBus.$on('taxCategoryDetail', Obj => {
      this.form.categoryName = Obj.category_name;
      this.form.tax_cat_hdr_id = Obj.tax_cat_hdr_id;
      this.form.startDate = Obj.start_date;
      this.form.endDate = Obj.end_date;
      this.form.status = Obj.active;
      this.getTaxCategoryDetails(Obj.tax_cat_hdr_id);
    });
  },
  methods: {
    getTaxCategoryDetails(tax_cat_hdr_id) {
      this.$store
        .dispatch('taxCat/getTaxCategoryDetails', tax_cat_hdr_id)
        .then(response => {
          const results = response.data.data;
          this.data = results.tax_category_details.map(detail => {
            detail.start_date_end_date = [
                new Date(detail.start_date),
                new Date(detail.end_date)
              ];
            return detail;
          });
          this.form.categoryName = results.category_name;
          this.form.tax_cat_hdr_id = results.tax_cat_hdr_id;
        });
    },
    addEditTaxCategory() {
      const details = this.data.map(data => {
        return {
          adhoc: data.adhoc,
          line_num: data.line_num,
          precedence0: data.precedence0,
          precedence1: data.precedence1,
          precedence2: data.precedence2,
          precedence3: data.precedence3,
          precedence4: data.precedence4,
          precedence5: data.precedence5,
          precedence6: data.precedence6,
          precedence7: data.precedence7,
          precedence8: data.precedence8,
          precedence9: data.precedence9,
          tax_cat_dtl_id: data.tax_cat_dtl_id,
          tax_master_id: data.tax_master_id
        };
      });
      const payload = {
        active: this.form.status,
        category_name: this.form.categoryName,
        start_date: commonHelper.formattedDate(this.form.start_date_end_date[0]),
        end_date: commonHelper.formattedDate(this.form.start_date_end_date[1]),
        
        tax_cat_hdr_id: this.form.tax_cat_hdr_id,
        tax_category_details: details
      };
      this.$store
        .dispatch('taxCat/addEditTaxCategory', payload)
        .then(response => {
          this.editMode = false;
          this.updateMode = false;
          this.showAlert = true;
          this.loader = false;
          this.$emit('updateTaxCategoryList');
          if (response.status === 201) {
            this.form.tax_cat_hdr_id = response.data.data.tax_cat_hdr_id;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        });
    },
    openTaxMasterModule(index) {
      this.tableIndex = index;
      if (this.data[this.tableIndex].tax_cat_dtl_id == 0) {
        this.showTaxMasterModule = true;
      }
    },
    selectedTaxName(item) {
      this.data[this.tableIndex].tax_name = item.tax_name;
      this.data[this.tableIndex].tax_master_id = item.tax_master_id;
      this.data[this.tableIndex].tax_rate = item.tax_rate;
      this.data[this.tableIndex].adhoc = item.adhoc;
      this.showTaxMasterModule = false;
    },
    addNewRow() {
      if (this.updateMode) {
        this.data.push({
          line_num: this.data.length + 1,
          tax_cat_dtl_id: 0,
          tax_name: null,
          tax_master_id: null,
          tax_rate: null,
          adhoc: null,
          precedence0: null,
          precedence1: null,
          precedence2: null,
          precedence3: null,
          precedence4: null,
          precedence5: null,
          precedence6: null,
          precedence7: null,
          precedence8: null,
          precedence9: null
        });
      }
    },
    removeRow(index) {
      if (this.updateMode) {
        this.data.splice(index, 1);
      }
    },
    enableInputs(index, commIndex) {
      this.detailIndex = index;
      this.responseIndex = commIndex;
    },
    hideTaxMasterModule() {
      this.showTaxMasterModule = false;
    },
    resetform() {
      this.form.categoryName = null;
      this.form.tax_cat_hdr_id = 0;
      this.form.startDate = null;
      this.form.endDate = null;
      this.form.status = true;
      this.data = [
        {
          line_num: 1,
          tax_cat_dtl_id: 0,
          tax_name: null,
          tax_master_id: null,
          tax_rate: null,
          adhoc: null,
          precedence0: null,
          precedence1: null,
          precedence2: null,
          precedence3: null,
          precedence4: null,
          precedence5: null,
          precedence6: null,
          precedence7: null,
          precedence8: null,
          precedence9: null
        }
      ];
    }
  }
};
